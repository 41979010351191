@import 'variables';

.filter-panel {
  background: $white;
  border: 1px solid $neutral-300;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;

  .form-group {
    margin-bottom: 0;
  }

  .select-wrap {
    flex: 1;
  }
}

.table-wrap {
  background: $white;
  border: 1px solid $neutral-200;
  border-radius: 8px;

  .btn-panel {
    padding: 14px 16px;
  }

  &.tabpanel {
    height: calc(100vh - 206px);
    .table-scroll {
      overflow: auto;
    }
  }
}

// .table-wrap {
//   padding: 16px 24px;
//   background: $white;
//   box-shadow: 0 1px 4px -1px rgba($black, .2), 0 0 3px -1px rgba($black, .2);
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   min-height: 300px;
//   overflow-y: hidden;
//   position: relative;
//   border-radius: 2px;

//   &.summary-result {
//     min-height: auto;
//     padding: 0;
//     flex: initial;
//   }

//   &.scheduler-result-chart {
//     flex: auto;
//     background: $grey-100;
//     box-shadow: none;
//   }

//   &.output-result {
//     padding: 0;
//     min-height: 200px;

//     .result_header {
//       border: 1px solid $grey-300;
//       .modal_select-wrap {
//         margin-bottom: 0;
//         flex: 1;

//         .react-select_single__menu-portal {
//           z-index: 4;
//         }
//       }
//     }

//     &.result-grafana {
//       .result_header {
//         border: none;
//       }
//     }
//   }

.status-column {
  font-weight: 400;
  padding: 6px 16px;
  border-radius: 50px;

  &__active {
    background-color: rgba(36, 144, 46, 0.1);
    color: $active;
  }

  &__suspend {
    background-color: $grey-200;
  }

  &__invited {
    background-color: rgba(64, 81, 88, 0.1);
    color: $info;
  }

  &__disabled {
    background-color: rgba(191, 42, 42, 0.1);
    color: $disabled;
  }
}

//     &__disable,
//     &__disabled,
//     &__enabled,
//     &__success,
//     &__error,
//     &__pending {
//       position: relative;
//       padding-left: 24px;
//       display: flex;
//       align-items: center;
//       font-weight: 400;

//       &:before {
//         content: '';
//         position: absolute;
//         background-repeat: no-repeat;
//         width: 20px;
//         height: 20px;
//         left: 0;
//       }
//     }

//     &__enabled,
//     &__success {
//       &:before {
//         background-image: url(../../img/check-circle.svg);
//       }
//     }

//     &__error {
//       &:before {
//         background-image: url(../../img/error.svg);
//       }
//     }

//     &__pending {
//       &:before {
//         background-image: url(../../img/info.svg);
//       }
//     }

//     &__disable,
//     &__disabled {
//       &:before {
//         background-image: url(../../img/disabled-grey.svg);
//       }
//     }
//   }

//   .check-col {
//     position: relative;
//     top: -15px;
//   }
// }

.no-data {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  flex: 1;

  img {
    max-width: 330px;
    margin: 0 auto 16px;
  }

  .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
    color: $neutral-700;

    &.second-string {
      font-size: 16px;
      color: $neutral-600;
    }
  }

  .btn {
    &.btn-primary {
      max-width: 164px;
      margin: 0 auto;
      padding: 12px 16px 12px 42px;
      text-decoration: none;
    }
  }

  .icon {
    margin-right: 9px;
  }
}
