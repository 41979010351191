@import 'variables';

.search-bar {
  position: relative;
  width: 328px;
  margin-right: 20px;
  border-radius: 6px;
  border: 1px solid $neutral-300;
  overflow: hidden;

  input {
    width: 100%;
    height: 100%;
    padding: 12px 12px 12px 36px;
    color: $neutral-600;
    font-size: 16px;
    outline: none;
    border: none;

    &:focus {
      border: none;
    }
  }

  .icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-48%);
    color: $neutral-700;
    font-size: 14px;
  }
}
