@font-face {
  font-family: 'icomoon';
  src: url('icomoon.eot?34560z');
  src: url('icomoon.eot?34560z#iefix') format('embedded-opentype'),
    url('icomoon.ttf?34560z') format('truetype'),
    url('icomoon.woff?34560z') format('woff'),
    url('icomoon.svg?34560z#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-leads-upload:before {
  content: '\e91b';
}
.icon-check-long:before {
  content: '\e929';
}
.icon-dots:before {
  content: '\e92a';
}
.icon-key:before {
  content: '\e92b';
}
.icon-pen:before {
  content: '\e92c';
}
.icon-plus:before {
  content: '\e92d';
}
.icon-plus-circle:before {
  content: '\e92e';
}
.icon-refresh1:before {
  content: '\e92f';
}
.icon-upload-file:before {
  content: '\e930';
}
.icon-checkmark:before {
  content: '\e90d';
}
.icon-edit-copy:before {
  content: '\e90c';
}
.icon-alert-circle:before {
  content: '\e904';
}
.icon-arrow-down:before {
  content: '\e905';
}
.icon-arrow-long-left:before {
  content: '\e906';
}
.icon-arrow-up:before {
  content: '\e907';
}
.icon-branch:before {
  content: '\e908';
}
.icon-calendar:before {
  content: '\e909';
}
.icon-check-circle:before {
  content: '\e90a';
}
.icon-dots-grid:before {
  content: '\e90b';
}
.icon-edit:before {
  content: '\e90e';
}
.icon-file:before {
  content: '\e90f';
}
.icon-file-download:before {
  content: '\e910';
}
.icon-filter:before {
  content: '\e911';
}
.icon-folder:before {
  content: '\e912';
}
.icon-folder-dot:before {
  content: '\e913';
}
.icon-folder-plus:before {
  content: '\e914';
}
.icon-home:before {
  content: '\e915';
}
.icon-log-out:before {
  content: '\e916';
}
.icon-mail:before {
  content: '\e917';
}
.icon-map:before {
  content: '\e918';
}
.icon-passcode:before {
  content: '\e919';
}
.icon-phone:before {
  content: '\e91a';
}
.icon-refresh:before {
  content: '\e91c';
}
.icon-report:before {
  content: '\e91d';
}
.icon-save:before {
  content: '\e91e';
}
.icon-search:before {
  content: '\e91f';
}
.icon-settings:before {
  content: '\e920';
}
.icon-trash:before {
  content: '\e921';
}
.icon-upload:before {
  content: '\e922';
}
.icon-upload-cloud:before {
  content: '\e923';
}
.icon-user:before {
  content: '\e924';
}
.icon-user-plus:before {
  content: '\e925';
}
.icon-users:before {
  content: '\e926';
}
.icon-x-close:before {
  content: '\e927';
}
.icon-chevron-right:before {
  content: '\e900';
}
.icon-chevron-left:before {
  content: '\e901';
}
.icon-chevron-down:before {
  content: '\e902';
}
.icon-chevron-up:before {
  content: '\e903';
}
.icon-eye:before {
  content: '\e9ce';
}
.icon-eye-blocked:before {
  content: '\e9d1';
}
.icon-arrow-left2:before {
  content: '\ea40';
}
