@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter-Regular.eot');
  src: url('../../fonts/Inter-Regular.woff') format('woff'),
    url('../../fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter-Medium.eot');
  src: url('../../fonts/Inter-Medium.woff') format('woff'),
    url('../../fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
