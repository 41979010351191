@import 'variables';

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;

  .img-loading {
    margin-right: 8px;
    animation: load 1s linear infinite;
    @keyframes load {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(-360deg);
      }
    }
  }

  &:focus {
    outline: 2px solid $blue-200;
  }

  &[disabled] {
    background-color: $neutral-600;
    opacity: 0.5;
    pointer-events: none;
  }

  &.btn-primary {
    background-color: $accent-500;
    border: 1px solid $accent-500;
    color: $white;
    transition: background-color $anim-speed;

    &:hover {
      background-color: $blue-500;
      border: 1px solid $blue-400;
    }

    &[disabled] {
      background-color: $black;
      border: 1px solid $black;
      opacity: 0.25;
    }
  }

  &.btn-secondary {
    background-color: $accent-100;
    border: 1px solid $accent-100;
    color: $accent-500;
    transition: background-color $anim-speed;

    &:hover {
      background-color: $neutral-200;
    }

    &[disabled] {
      background-color: $neutral-200;
      opacity: 0.5;
    }
  }

  &.hide {
    display: none !important;
  }

  // &.btn-success {
  //   background-color: $green-600;
  //   border: 1px solid $green-600;
  //   color: $white;
  //   transition: background-color $anim-speed;

  //   &:hover {
  //     background-color: darken($green-600, 10%);
  //   }

  //   &[disabled] {
  //     background-color: $grey-200;
  //     opacity: .5;
  //   }
  // }

  // &.btn-error {
  //   background-color: $red-600;
  //   border: 1px solid $red-600;
  //   color: $white;
  //   transition: background-color $anim-speed;

  //   &:hover {
  //     background-color: darken($red-600, 10%);
  //   }

  //   &[disabled] {
  //     background-color: $grey-200;
  //     opacity: .5;
  //   }
  // }

  // &.btn-grey {
  //   background-color: $grey-700;
  //   border: 1px solid $grey-700;
  //   color: $white;
  //   transition: background-color $anim-speed;

  //   &:hover {
  //     background-color: darken($grey-700, 10%);
  //   }

  //   &[disabled] {
  //     background-color: $grey-200;
  //     opacity: .5;
  //   }
  // }

  &.btn-with-icon {
    position: relative;
    padding: 12px 16px 12px 42px;

    .icon {
      font-family: 'icomoon';
      background-size: cover;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
      font-size: 15px;

      @media (min-width: 1600px) {
        font-size: 16px;
      }
    }

    &.reverse {
      padding: 8px 42px 8px 16px;

      .icon {
        left: auto;
        right: 16px;
      }
    }

    &.disabled {
      background-color: $grey-200;
      opacity: 0.5;
      pointer-events: none;
    }

    &.btn-back {
      font-size: 32px;
      font-weight: 500;
      background: none;
      border: none;
      margin-left: 0 !important;

      &:hover {
        color: $accent-500;

        .icon {
          &:before {
            color: $accent-500;
          }
        }
      }

      .icon {
        font-size: 21px;
        font-weight: 600;
        left: 0;
      }

      &:focus {
        outline: none;
      }
    }
  }

  &.btn-clear {
    border: 1px solid transparent;
    background-color: transparent;
    color: $grey-800;
    transition: background-color $anim-speed, border $anim-speed;

    &:hover {
      background-color: $grey-200;
      border: 1px solid $grey-200;
    }

    &:focus {
      outline: none;
    }

    &[disabled] {
      opacity: 0.5;

      &:hover {
        border: 1px solid transparent;
        background-color: transparent;
      }
    }
  }

  &.btn-icon {
    @extend %btn-none;
    display: flex;

    &:focus {
      outline: none;
    }

    &[disabled] {
      &:hover {
        border: 1px solid $grey-400;
        background-color: transparent;
        box-shadow: none;
      }
    }

    span {
      &:before {
        font-size: 16px;
      }
    }
  }

  &.btn-white {
    background: $white;
    color: $accent-500;
    border: 1px solid $neutral-300;

    &:hover {
      border: 1px solid $blue-200;
      box-shadow: 0 0 0 1px $blue-200;
    }

    .icon {
      margin-right: 9px;
    }
  }

  &.delete {
    color: $error;

    &:hover {
      border: 1px solid $error;
      box-shadow: 0 0 0 1px $error;
    }

    .icon {
      color: $error;
    }
  }

  &.dropdown {
    padding: 10px 42px 10px 24px;
    border-radius: 50px;
    border: 1px solid $neutral-300;
    background: $neutral-200;
    color: $neutral-700;
    font-size: 16px;
    font-weight: 500;
    transition: background $anim-speed, border $anim-speed;

    &.dropdown-lead-type {
      display: flex;
      justify-content: flex-start;
      min-height: 39px;
      min-width: 257px;
      border-radius: 8px;
      background: $white;
      z-index: 2;

      &:hover,
      &:focus {
        background-color: $white;
        border: 1px solid $blue-200;
      }
    }

    &:hover,
    &:focus {
      background-color: $accent-100;
      border: 1px solid $accent-500;
    }

    &.dropdown-accounts {
      min-width: 115px;
    }

    &:after {
      content: '\e902';
      font-family: 'icomoon';
      position: absolute;
      top: 51%;
      transform: translateY(-50%);
      right: 16px;
      line-height: 0;
      font-size: 11px;
    }

    &.open {
      &:after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &.link {
    display: inline-block;
    color: $link;
    font-weight: 400;
    padding: 0;
    border: none;
    background: none;
    outline: none;
    transition: color $anim-speed;

    &:hover {
      color: $accent-500;
      text-decoration: underline;
    }
  }
}

.link {
  display: inline-block;
  color: $accent-500;
  text-decoration: none;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
}

// .btn-view {
//   background: none;
//   border: none;
//   outline: none;
//   color: $link;
//   white-space: nowrap;

//   &:hover {
//     color: $blue-400;
//     text-decoration: underline;
//   }
// }

// .btn-pgn {
//   @extend %btn-none;
//   width: 36px;
//   height: 36px;
//   font-weight: 700;
//   color: $grey-700;

//   &[disabled] {
//     span {
//       &:before {
//         color: $grey-300;
//       }
//     }
//   }

//   &.arrows {
//     color: $grey-800;
//     font-size: 16px;
//     position: relative;

//     .icon {
//       @extend %absolute-center;
//       position: absolute;
//     }
//   }

//   &.selected {
//     border: 1px solid $grey-400;
//     border-radius: 4px;
//     color: $black;
//   }
// }

// .btn-arrow {
//   @extend %btn-none;
//   line-height: 0;

//   .icon {
//     &:before {
//       font-size: 12px;
//     }
//   }

//   .icon-sort {
//     &:before {
//       color: $grey-400;
//     }
//   }
// }

// a {
//   &.btn {
//     display: inline-block;
//     text-decoration: none;
//   }
// }

.react-tabs {
  .react-tabs__tab-list {
    border: 1px solid $neutral-300;
    border-radius: 100%;
    border-radius: 50px;
    padding: 8px;
    max-width: max-content;
    margin-bottom: 32px;
    overflow: hidden;
    background: $white;
  }

  .react-tabs__tab {
    font-weight: 500;
    padding: 8px 16px;
    background: $neutral-200;
    color: $neutral-800;
    border-radius: 50px;
    transition: background-color $anim-speed, color $anim-speed;

    &:hover {
      background: $accent-500;
      color: $white;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }

    &.react-tabs__tab--selected {
      background: $accent-500;
      color: $white;
    }
  }

  .tab-wrap-content {
    .search-bar {
      margin: 14px 16px;
    }
  }
}

.radio-tab-wrap {
  border: 1px solid $neutral-300;
  border-radius: 100%;
  border-radius: 50px;
  padding: 8px;
  max-width: max-content;
  margin-bottom: 32px;
  overflow: hidden;
  background: $white;

  label:not(:last-child) {
    margin-right: 8px;
  }

  input {
    display: none;

    &:checked {
      & + .control {
        background: $accent-500;
        color: $white;
      }
    }
  }

  .control {
    color: $neutral-800;
    font-weight: 500;
    font-size: 12px;
    padding: 8px 16px;
    background: $neutral-200;
    border-radius: 50px;
    transition: color $anim-speed, background $anim-speed;

    &:hover {
      background: $accent-500;
      color: $white;
    }
  }
}
