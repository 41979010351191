@import 'variables';
@import 'helpers';

// form,
// fieldset {
//   margin: 0;
//   padding: 0;
//   border-style: none;
// }

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='url'],
input[type='date'],
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  box-sizing: border-box;
  border: 1px solid $neutral-300;
  padding: 12px;
  border-radius: 8px;

  &:focus {
    border-color: $black;
  }
}

// select {
//   -webkit-border-radius: 0;
// }

textarea {
  resize: vertical;
  vertical-align: top;
}

button,
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='submit'] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $white inset !important;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  position: relative;

  label:not(.control),
  .radio-group_title {
    color: $neutral-800;
    font-weight: 500;
    margin-bottom: 8px;
  }

  &.readonly,
  .readonly {
    pointer-events: none;
  }

  input,
  .dropdown_select,
  textarea {
    font-size: 16px;
    color: $neutral-600;
    padding: 12px;
    border: 1px solid $neutral-300;
    border-radius: 8px;
    background: $white;
    outline: none;
    position: relative;

    &:hover,
    &:focus {
      border: 1px solid $blue-200;
      // box-shadow: 0 0 0 1px $blue-200;
    }
  }

  input {
    &.error {
      position: relative;
      bottom: 0;
      border: 1px solid $error;
      color: $error;
    }
  }

  .error {
    display: block;
    order: 2;
    position: absolute;
    bottom: -15px;
    color: $error;
    width: 100%;

    &:first-letter {
      text-transform: uppercase;
    }

    &:before {
      content: '\e904';
      font-family: icomoon;
      font-size: 15px;
      color: $error;
      position: absolute;
      z-index: 2;
      top: -31px;
      right: 12px;
      background: $white;
    }
  }

  .error + input,
  .error + textarea {
    color: $error !important;
  }

  .error + input,
  .error + .control-file,
  .error + textarea,
  .error + button {
    border: 1px solid $error;
    // box-shadow: 0 0 0 1px $error;
  }

  .error + input::placeholder,
  .error + textarea::placeholder {
    color: $error;
    opacity: 1;
  }

  &.select-wrap {
    display: flex;

    .configure-value-list {
      margin: 5px 0 0;

      li {
        display: flex;
        column-gap: 8px;
        margin-bottom: 16px;

        .css-1nmdiq5-menu {
          margin-top: 4px;
        }

        &:last-child:not(:nth-child(1), :nth-child(2)) {
          .css-1nmdiq5-menu {
            top: auto;
            bottom: 32px;
          }
        }

        &:nth-child(2):last-child {
          .css-1nmdiq5-menu {
            top: -124px;
          }
        }
      }
    }

    .css-3iigni-container {
      .css-16xfy0z-control {
        border-radius: 8px;
        font-size: 16px;
        background: none;
        min-height: 45px !important;
      }

      .css-1fdsijx-ValueContainer {
        padding: 8px;
      }

      .css-894a34-indicatorSeparator {
        display: none;
      }
    }

    .form-group {
      margin-bottom: 0;
      flex: 1;
    }

    .field-type_wrap {
      display: flex;
    }

    .css-t3ipsp-control,
    .css-13cymwt-control,
    .css-v68sna-control {
      border: 1px solid $grey-300;
      border-radius: 8px;

      &:hover,
      &:focus,
      &:focus-within {
        border: 1px solid $blue-200;
        // box-shadow: 0 0 0 1px $blue-200;

        .css-15lsz6c-indicatorContainer,
        .css-1xc3v61-indicatorContainer,
        .css-1hb7zxy-IndicatorsContainer {
          color: hsl(0, 0%, 80%);
        }
      }

      input {
        &:hover,
        &:focus {
          border: none;
          box-shadow: none;
        }
      }

      .css-1u9des2-indicatorSeparator {
        display: none !important;
      }
    }

    .css-1wlit7h-NoOptionsMessage {
      text-align: left;
      color: $black;
    }

    .css-tr4s17-option {
      background-color: $blue-100;
      color: $black;
    }

    .css-d7l1ni-option {
      background-color: $white;

      &:hover {
        background-color: $active-100;
      }
    }

    .css-10wo9uf-option {
      background-color: $white;
    }

    input {
      padding: 9px 12px;
    }
  }

  .btn {
    position: absolute;
    width: 269px;
    height: 45px;
    top: 23px;
    display: flex;
    padding: 0 16px 0 0;
    border: 1px solid $grey-300;
    justify-content: flex-start;
    padding: 0 16px;
    font-weight: 400;
    color: $neutral-600;

    &:hover {
      border: 1px solid $blue-200;
      box-shadow: 0 0 0 1px $blue-200;

      span {
        &:before {
          color: $blue-200;
        }
      }
    }

    span {
      &:before {
        font-size: 12px;
        color: $neutral-700;
        position: absolute;
        top: 16px;
        right: 16px;
      }
    }
  }

  &.input-btn {
    .btn {
      position: absolute;
      width: 20px;
      background: none;
      border: none;
      outline: none;
      padding: 0;
      right: 0;
      top: 22px;

      &:hover {
        border: none;
        box-shadow: none;
      }

      span {
        &:before {
          font-size: 16px;
        }
      }
    }

    input {
      padding: 12px 42px 12px 12px;
    }
  }

  //   &.control-group {
  //     display: inline-flex;
  //   }

  //   .control_prompt {
  //     font-size: 12px;
  //     font-weight: 400;
  //   }

  //   &.radio-group {
  //     .control_radio:not(:last-child) {
  //       position: relative;
  //       margin-right: 12px;
  //       padding-right: 12px;

  //       &:before {
  //         content: '';
  //         position: absolute;
  //         width: 1px;
  //         height: 22px;
  //         background-color: $grey-300;
  //         right: 0;
  //         top: 50%;
  //         transform: translateY(-50%);
  //       }
  //     }
  //   }

  &.password {
    position: relative;

    input {
      padding: 12px 40px 12px 12px;
      order: 1;
    }

    .pass-wrap {
      z-index: 2;
    }

    .icon {
      font-family: 'icomoon';
      position: absolute;
      font-size: 15px;
      top: 38px;
      right: 12px;
      z-index: 2;
      cursor: pointer;
    }
  }

  //   &.tab-panel {
  //     padding: 0 24px;
  //     display: block;
  //     border-bottom: 1px solid $grey-300;

  //     .control_radio {
  //       cursor: pointer;
  //       text-decoration: none;
  //       color: $grey-600;
  //       background: $grey-300;
  //       border-top: 2px solid $grey-300;;
  //       font-weight: 700;
  //       transition: background-color .3s;

  //       &:before {
  //         display: none;
  //       }

  //       &.control {
  //         margin: 0;
  //         padding: 8px 40px 10px;
  //       }

  //       &.active {
  //         background-color: $white;
  //         cursor: default;
  //         color: $blue-500;
  //         border-top: 2px solid $blue-400;
  //         box-shadow: 0 1px 0 $white;
  //       }
  //     }
  //   }

  //   .tabs-group {
  //     border: 1px solid rgba($grey-600, .5);
  //     align-self: flex-start;
  //     border-radius: 3px;
  //     padding: 2px;

  //     .control_tab-label {
  //       padding: 0;
  //       position: relative;
  //       min-width: 100px;

  //       &:not(:last-child) {
  //         margin-right: 9px;

  //         &:before {
  //           content: '';
  //           position: absolute;
  //           height: 100%;
  //           width: 1px;
  //           background: $grey-300;
  //           right: -5px;
  //         }
  //       }

  //       &:hover {
  //         input ~ .control_tab {
  //           background: $blue-200;
  //         }

  //         .control_tab {
  //           color: $white;
  //         }
  //       }
  //     }

  //     .control_tab {
  //       padding: 6px 12px;
  //       border-radius: 3px;
  //       text-transform: capitalize;
  //       width: 100%;
  //       text-align: center;
  //     }

  //     input:checked ~ .control_tab {
  //       background: $blue-400;
  //       color: $white;

  //       &:hover {
  //         background: $blue-400;
  //         cursor: default;
  //       }
  //     }
  //   }

  //   &.disabled {
  //     pointer-events: none;
  //     opacity: .5;
  //   }

  //   &.hidden {
  //     display: none;
  //   }
}

.control {
  display: inline-flex;
  align-self: flex-start;
  position: relative;
  padding-left: 25px;
  cursor: pointer;

  &:hover {
    input ~ .control_indicator {
      background: $accent-500;
    }

    .control_indicator {
      border: 1px solid $neutral-600;
    }
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  }
}

.control_indicator {
  position: absolute;
  top: -10px;
  left: 8px;
  height: 14px;
  width: 14px;
  background: $white;
  border: 2px solid $neutral-600;
  border-radius: 3px;
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
}

.control_checkbox {
  .control_indicator {
    &:after {
      left: 4px;
      top: 1px;
      width: 5px;
      height: 8px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  input:checked ~ .control_indicator {
    background: $blue-400;
    border: 1px solid $blue-400;
    &:after {
      display: block;
    }
  }
}

.control_radio {
  .control_indicator {
    border-radius: 50%;
    &:after {
      @extend %absolute-center;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: $white;
    }
  }

  input:checked ~ .control_indicator {
    background: $white;
    border: 2px solid $blue-200;
    &:after {
      display: block;
      background: $blue-400;
      width: 8px;
      height: 8px;
    }
  }
}

// .wrapper_select_single {
//   padding: 0 4px 4px;
//   .react-select_single__control {
//     min-height: 1px;
//     margin: 0;
//     font-size: 14px;
//     font-weight: 400;
//     border: 1px solid $grey-600;
//     border-radius: 3px;
//     background: $grey-100;

//     &:hover {
//       border: 1px solid $blue-200;
//       box-shadow: 0 0 0 1px $blue-200;
//     }

//     &:focus {
//       border: 1px solid $blue-200;
//       box-shadow: 0 0 0 1px $blue-200;
//     }

//     &.react-select_single__control--is-focused {
//       border: 1px solid $blue-200;
//       box-shadow: 0 0 0 1px $blue-200;
//     }

//     .react-select_single__single-value {
//       margin: 0;
//       padding: 7px 36px 7px 12px;
//     }
//   }

//   .react-select_single__menu-portal {
//     display: block !important;
//   }

//   .react-select_single__menu {
//     border-radius: 3px;
//     margin-top: 3px;
//   }
// }

// .react-select_single__control {
//   .react-select_single__value-container {
//     padding: 0 0 0 10px;
//     position: relative;

//     &:before {
//       content: "\e917";
//       font-family: icomoon;
//       position: absolute;
//       right: 10px;
//       top: 50%;
//       transform: translateY(-50%) rotate(90deg);
//       font-size: 12px;
//       color: $grey-600;
//     }
//   }

//   .react-select_single__indicator {
//     padding: 0;
//     display: none;
//   }

//   .react-select_single__indicator-separator {
//     display: none;
//   }

//   &.react-select_single__control--menu-is-open {
//     .react-select_single__value-container {
//       &:before {
//         content: "\e917";
//         font-family: icomoon;
//         position: absolute;
//         right: 10px;
//         top: 50%;
//         transform: translateY(-50%) rotate(-90deg);
//         font-size: 12px;
//         color: $grey-600;
//       }
//     }
//   }

//   .react-select_single__option {
//     font-size: 14px;
//     font-family: 'Inter';
//     font-weight: 400;
//     padding: 10px 17px;

//     &.react-select_single__option--is-focused {
//       background-color: $active-100;
//       color: $black;
//     }

//     &.react-select_single__option--is-selected {
//       background-color: $blue-100;
//       color: $black;
//     }
//   }
// }

// .wrapper_select_single,
// .items-count {
//   .react-select_single__option {
//     font-size: 14px;
//     font-family: 'Inter';
//     font-weight: 400;
//     padding: 10px 17px;

//     &.react-select_single__option--is-focused {
//       background-color: $active-100;
//       color: $black;
//     }

//     &.react-select_single__option--is-selected {
//       background-color: $blue-100;
//       color: $black;
//     }
//   }
// }

// .control-file {
//   color: $black;
//   border: 1px solid $grey-600;
//   border-radius: 3px;
//   background: $white;
//   outline: none;
//   max-width: 536px;

//   label {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     position: relative;

//     &.input-wrap {
//       padding-bottom: 0;
//       opacity: 1;
//     }
//   }

//   .title {
//     padding: 0 50px 0 12px;
//     font-weight: 400;
//   }

//   .icon {
//     display: none;
//     position: absolute;
//     width: 16px;
//     height: 16px;
//     border-radius: 100%;
//     background-color: $grey-600;
//     right: 120px;
//     cursor: pointer;
//     transition: opacity $anim-speed;

//     &:hover {
//       opacity: .5;
//     }

//     &:before {
//       @extend %absolute-center;
//       position: absolute;
//       color: $white;
//       font-size: 8px;
//     }

//     &.show {
//       display: block;
//     }
//   }

//   input {
//     display: none;
//   }

//   .btn {
//     border: 1px solid $grey-400;
//     border-left: 2px solid $grey-400;
//     border-radius: 0 3px 3px 0;
//     padding: 8px 12px;
//     color: $grey-800;
//     font-weight: 700;
//     cursor: pointer;

//     &:hover,
//     &:focus {
//       outline: 2px solid $blue-200;
//     }
//   }
// }

// .control-file_title {
//   display: block;
//   margin-bottom: 4px;
//   font-weight: 600;
//   color: $black;
//   opacity: .5;
// }

// // Ace editor
// .code-editor {
//   max-width: none;

//   .ace_editor {
//     border-radius: 3px;
//     border: 1px solid $grey-300;
//     resize: auto;
//     min-width: 536px;
//     min-height: 76px;
//   }

//   .ace_gutter-cell {
//     color: $black;
//     opacity: .5;
//   }

//   .ace_print-margin {
//     display: none;
//   }
// }

// checkbox switch
.switch-wrap {
  margin-bottom: 24px;

  .control_prompt {
    font-size: 12px;
    font-weight: 400;
  }

  .title {
    white-space: nowrap;
    font-size: 16px;
    font-weight: 500;
    margin-left: 16px;

    &.show-reports-title {
      margin: 0 16px 0 0;
    }
  }

  label {
    position: relative;
    display: flex;
    align-items: center;

    &.switch-reverse {
      flex-direction: row-reverse;
      justify-content: space-between;

      .title {
        margin: 0 16px 0 0;
      }
    }
  }
}

.switch {
  display: flex;
  align-items: center;
  width: 36px;
  height: 20px;
  border-radius: 50px;
  position: relative;

  .knobs,
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .layer {
    border-radius: 100px;
    width: 36px;
    height: 20px;
    background: $neutral-300;
    transition: 0.3s ease all;
    z-index: 1;
  }

  .knobs {
    z-index: 2;

    &:before {
      content: '';
      position: absolute;
      top: 2px;
      left: 3px;
      width: 16px;
      height: 16px;
      background-color: $white;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
      border-radius: 50%;
      transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
    }
  }

  .switch-checkbox:checked + .knobs:before {
    content: '';
    left: 17px;
    box-shadow: none;
  }

  .switch-checkbox:checked ~ .layer {
    background-color: $accent-500;
  }
}

.switch-checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

// form-group-block for settings and details modal
.form-group-block {
  background: $neutral-200;
  padding: 16px 16px 0;
  border-radius: 8px;
  margin-bottom: 8px;

  &.actions {
    padding: 16px;
  }

  h3 {
    margin-bottom: 24px;
    width: 100%;
  }

  .form-group {
    margin-bottom: 16px;
  }

  .css-1fdsijx-ValueContainer {
    padding: 8px;
  }

  &.no-bg {
    background: $white;
  }
}
