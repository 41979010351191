@import 'variables';

.banner {
  position: fixed;
  z-index: 9998;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.banner-container {
  border-radius: 16px;
  border: 1px solid $neutral-300;
  background: $white;
  min-height: 308px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 480px;
  display: flex;
  flex-direction: column;
  z-index: 9999;

  &.success {
    .info-body {
      .icon {
        color: $green;
        background: $light-green;

        &:before {
          content: '\e90a';
        }
      }
    }
  }

  &.error {
    .info-body {
      .icon {
        color: $clear;
        background: rgba(232, 29, 29, 0.1);

        &:before {
          content: '\e904';
        }
      }
    }
  }

  // &.error {
  //   background-color: $danger-200;
  //   box-shadow: 0 0 6px 0 $danger-400;

  //   &:before {
  //     background-image: url(../../img/error.svg);
  //   }
  // }

  // &.info {
  //   background-color: $info;
  //   box-shadow: 0 0 6px 0 $border-info;

  //   &:before {
  //     background-image: url(../../img/info.svg);
  //   }
  // }

  // &.success {
  //   background-color: $success;
  //   box-shadow: 0 0 6px 0 $border-success;

  //   &:before {
  //     background-image: url(../../img/check-circle.svg);
  //   }
  // }

  // &.warning {
  //   background-color: $alert;
  //   box-shadow: 0 0 6px 0 $border-alert;

  //   &:before {
  //     background-image: url(../../img/alert.svg);
  //   }
  // }

  .info-header {
    padding: 30px 30px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .icon {
      &:hover {
        &:before {
          color: $accent-500;
        }
      }

      &:before {
        color: $neutral-600;
        font-size: 12px;
        transition: color $anim-speed;
      }
    }

    .btn.btn-icon {
      border: none;
    }
  }

  .info-body {
    padding: 12px 30px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 160px;
    flex: 1;

    .icon {
      font-size: 26px;
      border-radius: 100%;
      padding: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
      width: 64px;
      height: 64px;

      &:before {
        content: '';
        font-family: 'icomoon';
        line-height: 1.15;
        height: 26px;
      }
    }

    h2 {
      margin-bottom: 8px;
    }

    .description {
      color: $neutral-600;
      font-size: 16px;
      text-align: center;
      white-space: pre-line;
    }
  }

  .info-footer {
    padding: 16px 30px;
    border-top: 1px solid $neutral-300;
    display: flex;
    justify-content: center;

    .btn {
      width: auto;
      margin: 0 8px;

      &.btn-clear {
        color: $clear;

        &:hover {
          background: $accent-100;
        }
      }
    }
  }
}

.banner-modal {
  background: rgba(255, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 24px 16px;
  margin-bottom: 24px;

  .icon {
    font-size: 15px;
    border-radius: 100%;
    padding: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    margin-right: 8px;

    &:before {
      font-family: 'icomoon';
      line-height: 1.15;
      height: 16px;
    }
  }

  &.success {
    .icon {
      color: $green;
      background: $light-green;

      &:before {
        content: '\e90a';
      }
    }
  }

  &.error {
    .icon {
      color: $clear;
      background: rgba(232, 29, 29, 0.1);

      &:before {
        content: '\e904';
      }
    }

    .title {
      color: $error;
    }
  }

  .title {
    font-size: 16px;
  }
}
