// colors
$black: #000;
$white: #fff;
$link: #3a5be0;
$primary-bg: #f5f5f5;
$error: #e81d1d;
$danger: #cc0022;
$clear: #d92d20;
$gray-50: #f9fafb;
$primary-500: #007dc6;
$orange: #de8e17;
$green: #33be27;
$light-green: rgba(51, 190, 39, 0.1);
$active-100: #f2f3fc;
$danger-200: #fae1e5;
$danger-400: #c26171;
$black-opacity-50: #e68091;
$black-opacity-60: #666;
$active-100: #f2f3fc;

$neutral-100: #fff;
$neutral-200: #f7f7f7;
$neutral-300: #f1f1f1;
$neutral-600: #838388;
$neutral-700: #363941;
$neutral-800: #1f2125;

// primary colors
$accent-100: #dcf2fe;
$accent-500: #3a5be0;

$blue-100: #d6ddff;
$blue-200: #99aaff;
$blue-300: #456aff;
$blue-400: #4552e5;
$blue-500: #363ab2;

$grey-50: #f9fafb;
$grey-100: #f2f4f7;
$grey-200: #c5c5c5;
$grey-300: #d7d7d7;
$grey-400: #b8b8b8;
$grey-500: #667085;
$grey-600: #808080;
$grey-700: #4d4d4d;
$grey-800: #333;
$grey-900: #101828;

// banners
// $error: #fae1e5;
// $border-error: #c26171;
$success: #dcf2dc;
$border-success: #47b247;
$success-400: #6eb86e;
$info: #405158;
$border-info: #5995b2;
$info-400: #7ab1cc;
$alert: #fae9c8;
$border-alert: #d6862b;
$active: #24902e;
$disabled: #bf2a2a;

// Body
$base-text-color: $neutral-700 !default;
$base-background-color: $neutral-200 !default;
$font-size-base: 12px !default;
$font-family-base: 'Inter';
$line-height-base: 1.21 !default;
$base-min-width: 320px;

// Headers
$h1-fonts-size: 32px !default;
$h2-fonts-size: 24px !default;
$h3-fonts-size: 20px !default;
$h4-fonts-size: 16px !default;

$anim-speed: 0.3s;

$z-index: 2000;
