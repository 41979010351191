@import '../../assets/scss/base/variables';
@import '../../assets/scss/base/helpers';

.header {
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 100%;
  background: $neutral-800;
  border-radius: 0 12px 12px 0;
  border-top: 1px solid $neutral-700;
  border-right: 1px solid $neutral-700;
  border-bottom: 1px solid $neutral-700;

  .logo-wrap {
    padding: 32px 24px;
    border-bottom: 1px solid $neutral-700;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 138px;
    }
  }

  .version {
    font-size: 16px;
    color: $neutral-600;
  }
}

.navbar {
  padding: 16px 0;
  border-bottom: 1px solid $neutral-700;
  flex: 1;
}

.navbar_list {
  @extend %list-reset;

  &.int_list {
    max-height: 265px;
    overflow-y: auto;
    .navbar_item {
      padding-left: 56px;
      justify-content: space-between;
    }
    &::-webkit-scrollbar{
      background-color: $neutral-700;
    }
  }

  li {
    font-size: 16px;
  }

  .navbar_item {
    padding: 18px 22px;
    color: $white;
    display: flex;
    align-items: center;
    text-decoration: none;
    line-height: 1;
    border-left: 4px solid $neutral-800;
    transition: background-color $anim-speed, border $anim-speed;
    cursor: pointer;

    &:hover,
    &.active {
      background-color: $neutral-700;
      border-left: 4px solid $accent-500;
    }

    .icon-chevron-down,
    .icon-chevron-up {
      margin-right: 0;
      flex: 1;
      text-align: end;

      &:before {
        font-size: 10px;
      }
    }

    .count {
      font-size: 12px;
      font-weight: 500;
      color: $accent-500;
      background: $accent-100;
      border-radius: 6px;
      padding: 6px 8px;
    }
  }

  .icon {
    margin-right: 18px;
  }
}

.profile-wrap {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .name-wrap {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .name {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 4px;
    color: $white;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .role {
    font-size: 16px;
    color: $accent-100;
  }

  .btn.btn-icon {
    border: none;
    background: $neutral-700;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    padding: 0;

    &:hover {
      .icon {
        color: $blue-200;
      }
    }

    &:focus {
      outline: $blue-200;
    }

    .icon {
      color: $white;
      font-size: 15px;
      transition: color $anim-speed;
    }
  }
}
