// Typography
@import 'variables';

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  font-weight: 400;
  color: $black;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
  font-family: 'Inter';
  font-weight: 500;
  margin: 0 0 26px;
  color: $neutral-800;
  line-height: $line-height-base;
}

h1,
.h1 {
  font-size: $h1-fonts-size;
}

h2,
.h2 {
  font-size: $h2-fonts-size;
}

h3,
.h3 {
  font-size: $h3-fonts-size;
}

h4,
.h4 {
  font-size: $h4-fonts-size;
}

p {
  margin: 0 0 1em;
}
