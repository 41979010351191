@import 'variables';
@import 'helpers';

.rc-tooltip {
  border-radius: 12px;

  .rc-tooltip-arrow {
    display: none;
  }
}

.rc-tooltip-inner {
  background: $neutral-700;
  padding: 16px;
  box-shadow: 0px 0px 20px 4px rgba(191, 191, 191, 0.25);
  border-radius: 12px;
  font-size: 14px;
  color: $white;
  line-height: 1;
}
