// list reset
%list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

// clearfix
%clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%flex-align-center {
  display: flex;
  align-items: center;
}

%flex-justify-center {
  display: flex;
  justify-content: center;
}

%btn-none {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  margin: 0;
}

%btn-link {
  font-size: 16px;
  font-weight: 500;
  background: none;
}

%absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}