@import '../../assets/scss/base/variables';
@import '../../assets/scss/base/helpers';

.loader-wrap {
  @extend %absolute-center;
  position: absolute;
  display: flex;
  flex-direction: column;

  .loader {
    width: 80px;
    height: 80px;
    border: 6px solid $neutral-300;
    border-bottom-color: $accent-500;
    border-radius: 50%;
    margin-bottom: 10px;
    animation: rotation 3s linear infinite;

    @keyframes rotation {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .title {
    color: $neutral-800;
    display: inline-block;
    position: relative;
    font-size: 14px;
  }
  .title:after {
    content: '';
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: currentColor;
    position: absolute;
    bottom: 6px;
    right: 18px;
    animation: anim-dash 1s linear infinite;

    @keyframes anim-dash {
      0% {
        box-shadow: 6px 0 rgba(255, 255, 255, 0), 12px 0 rgba(255, 255, 255, 0);
      }
      50% {
        box-shadow: 6px 0 $neutral-600, 12px 0 rgba(255, 255, 255, 0);
      }
      100% {
        box-shadow: 6px 0 $neutral-600, 12px 0 $neutral-600;
      }
    }
  }
}
