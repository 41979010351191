.modal_content {
  position: fixed;
  padding: 0;
  margin: 0;
  width: 600px;
  overflow: hidden;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2), 0 0 20px -5px rgba(0, 0, 0, 0.1);
  z-index: 2001;
  background: $white;

  &.datapicker-wrap {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 20px 4px rgba(191, 191, 191, 0.25);
    border-radius: 12px;
    width: 340px;

    .modal_header {
      padding: 26px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: $neutral-200;

      h2 {
        margin-bottom: 0;
        position: relative;
        padding-left: 38px;

        &:before {
          content: '\e909';
          transform: translateY(-44%);
          position: absolute;
          top: 50%;
          left: 0;
          font-family: 'icomoon';
          font-size: 20px;
          color: $accent-500;
        }
      }

      .btn {
        &:hover {
          .icon {
            color: $neutral-700;
          }
        }
      }

      .icon-x-close {
        font-size: 12px;
        color: $neutral-600;
        transition: color $anim-speed;
      }
    }

    .rdp {
      margin: 0;
    }

    .rdp-month {
      width: 100%;
    }

    .rdp-table {
      margin: 0 auto 4px;

      .rdp-head_row {
        th {
          font-weight: 500;
          font-size: 14px;
          color: $grey-600;
        }
      }
    }

    .rdp-button {
      color: $grey-800;
      font-size: 14px;
      transition: background-color $anim-speed, color $anim-speed;

      &:hover {
        &:not([disabled]):not(.rdp-day_selected) {
          background: $accent-500;
          color: $white;
        }
      }

      &.rdp-day_selected,
      &.rdp-day_selected:focus-visible,
      &.rdp-day_selected:hover {
        background: $accent-100;
      }
    }

    .rdp-day_outside {
      opacity: 1;
      color: $grey-200;
    }

    .rdp-button_reset {
      &.rdp-day_range_start {
        background: $accent-500;
        color: $white;

        &:hover {
          background: $accent-500;
          color: $white;
        }

        &:before {
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &.rdp-day_range_end,
      &.rdp-day_range_start {
        border-radius: 100px !important;
        overflow: visible;
        border: none;
        position: relative;

        &:before {
          content: '';
          background: $accent-100;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: -1;
        }
      }

      &.rdp-day_range_end {
        background: $white;
        box-shadow: inset 0px 0px 0px 2px rgb(220, 242, 254);

        &:before {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
        }

        &.rdp-day_range_start {
          background: $accent-500;
          color: $white;
          box-shadow: none;

          &:before {
            display: none;
          }

          &:hover {
            background: $accent-500;
            color: $white;
          }
        }
      }
    }
  }

  &.modal_visible {
    overflow: visible;

    &.with-create-new-folder {
      & > .modal_header > h2:before {
        content: '\e914';
      }

      & > .modal_body {
        min-height: 306px;

        & > .select-wrap {
          position: relative;
  
          .btn.link {
            @extend %btn-link;
            position: absolute;
            top: -87px;
            left: 290px;
            width: auto;
            padding: 0;
            border: none;
            color: $link;
            z-index: 1;
  
            &:hover {
              box-shadow: none;
            }
          }
        }
      }
    }

    .modal_header {
      border-radius: 16px 16px 0 0;
    }

    .modal_body {
      overflow: visible;
    }
  }
}
