@import 'vendors/normalize';

@import 'base/variables';
@import 'base/reset';
@import 'base/typography';
@import 'base/helpers';
@import 'base/fonts';

@import 'base/buttons';
@import 'base/forms';
@import 'base/banners';
@import 'base/table_wrap';
@import 'base/tooltip';
@import 'base/search';
@import 'base/modal';
@import '../fonts/icomoon/icomoon.scss';
@import 'react-tabs/style/react-tabs.scss';
@import 'rc-tooltip/assets/bootstrap_white.css';
@import 'react-day-picker/dist/style.css';

html {
  height: 100%;
  min-width: $base-min-width;
  min-height: 320px;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  width: 100%;
  min-width: 1080px;
  height: 100%;
  // overflow-x: auto;
  overflow: hidden;
  background-color: $neutral-200;
}

#root,
main {
  height: 100%;
}

main {
  position: relative;
  display: flex;
}

.layout_section {
  padding: 40px 24px 24px;
  width: calc(100% - 280px);
  overflow-y: auto;
}

.container {
  // padding-left: 15px;
  // padding-right: 15px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.min-height {
  min-height: 100vh;
}

.header-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;

  h1 {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
  }

  .btn-wrap {
    display: flex;

    .btn {
      margin-left: 8px;
    }
  }
}

.w-32 {
  width: 32%;
}

.w-49 {
  width: 49%;
}

.w-66 {
  width: 66%;
}

.w-100 {
  width: 100%;
}

.w-440 {
  width: 440px;
}

.w-760 {
  width: 760px;
}

.modal_on_modal {
  &.w-760 {
    width: 760px;
  }
}

::-webkit-scrollbar-corner {
  background-color: $neutral-200;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: $neutral-200;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

::-webkit-scrollbar-track {
  border: none;
  border-radius: 4px;
  box-shadow: none;
}

::-webkit-scrollbar-thumb {
  background-color: $accent-500;
  background-clip: content-box;
  border: 1px solid transparent;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $accent-500;
}

::-webkit-scrollbar-thumb:active {
  background-color: $accent-500;
}
